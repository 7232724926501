import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
import lazyLoadComponent from '@/scripts/lazyLoadComponent'
import SkeletonBox from '@/components/SkeletonBox'
import { API_CALL_STATUS } from "@/constant/config";
export default {
    extends: baseComponent,
    components: {
        PromotionEdit: lazyLoadComponent({
            componentFactory: () => import('@/views/adminManager/promotion/PromotionEdit.vue'),
            loading: SkeletonBox
        }),
        PromotionDetail: lazyLoadComponent({
            componentFactory: () => import('@/views/adminManager/promotion/PromotionDetail.vue'),
            loading: SkeletonBox
        })
    },
    data() {
        return {
            listData: [],
            isLoading: false,
            pagination: {
                status: 1,
                querySearch: '',
                orderBy: 'A.CreatedAt',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 50,
                outRowsNumber: 0
            }
        }
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.auth.user,
            rolesInfo: (state) => state.auth.roles,
            listStaff: (state) => state.staffs,
            decentralization: (state) => state.auth.decentralization
        })
    },

    mounted() {},
    beforeDestroy() {},
    methods: {
        searchList() {}
    }
}